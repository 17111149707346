@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

:root {
  --tooltip-color: rgb(50, 60, 70);
  --tooltip-down-color: rgb(50, 60, 70);
  --main-button-color: #ffbcf2;
  --main-button-hover-color: #fc34c0;

  --landing-page-main-button: #fb34c0;

  --clevero-color: #83b8da;
  --clevero-hover-color: #548caf;

  --left-side: 0px;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ------------------for toggle------------------ */
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-center .toggle-container {
  height: 2.6rem;
  max-width: 22rem;
  width: 100%;
  border-radius: 1.2rem;
  /* background-color: bisque; */
  background-color: rgb(255, 237, 213);
  position: relative;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.125);
  margin: 1rem;
  color: rgb(43, 43, 43);
}

.flex-center .toggle-container div {
  position: absolute;
  font-size: 1rem;
  font-weight: 600;
  top: 0.5rem;
}
.flex-center .toggle-container div:nth-child(1) {
  left: 12%;
}
.flex-center .toggle-container div:nth-child(2) {
  right: 12%;
}

.flex-center .toggle-container .toggle-button {
  height: 2.6rem;
  width: 50%;
  z-index: 10;
  /* background-color: rgba(255, 0, 0, 0.226); */
  background-color: #4f46aff6;
  border-radius: 1.2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: left 0.2s ease;
  cursor: initial;
}

.flex-center .toggle-container .toggle-button-right {
  left: 50%;
}

.display-box {
  max-width: 100%;
  padding: 2rem;
  font-size: 1.4rem;
  border-radius: 0.8rem;
  border: 2px solid bisque;
  background-color: bisque;
  margin: 2rem;
  font-weight: 500;
  letter-spacing: 0.45px;
  text-align: center;
}
.box-red {
  background-color: rgb(241, 134, 134);
}

@media (max-width: 768px) {
  .flex-center .toggle-container div {
    font-size: 0.98rem;
    font-weight: 500;
    top: 0.52rem;
  }
}

.large-text {
  /* font-size: 24px !important; */
  position: relative !important;
}
.tooltiptitle {
  position: absolute !important;
  top: -34px;
  left: var(--left-side);
  line-height: 15px;
  /* opacity: 0.2; */
  font-size: 0.72rem;
  padding: 0.4rem 0.7rem;
  color: white;
  font-weight: 300;
  background-color: var(--tooltip-color);
  border-radius: 6px;
  transform: scale(0);
  transition: transform 0.3s ease;
  box-shadow: 3px 3px 15px -3px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  min-width: 160px;
}
.tooltiptitle::before {
  content: "";
  width: 0px;
  height: 0px;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 16px solid var(--tooltip-down-color);
  position: absolute;
  top: 20px;
  left: 9px;
  z-index: -10;
}
.large-text:hover .tooltiptitle {
  transform: scale(0.9);
}

@media (max-width: 540px) {
  .tooltiptitle {
    top: -40px;
    padding: 0.28rem;
    font-size: 0.66rem;
    line-height: 13px;
  }
  .tooltiptitle::before {
    top: 26px;
    transform: scale(0.85);
  }
}

.landing-page-main {
  height: 94vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.text-stroke {
  -webkit-text-stroke: 0.6px rgba(0, 0, 0, 0.11);
}

.footer {
  display: flex;
  font-weight: 700;
  font-size: 1.02rem;
  height: 3vh;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 4%;
  margin-bottom: 0.5rem;
}

.footer div {
  display: inline-block;
  cursor: pointer;
}

.footer div span {
  color: var(--clevero-color);
}

.footer div span {
  color: var(--clevero-color);
  font-weight: 700;
}
/* 
.footer div:hover span.vbacspan {
  color: var(--clevero-hover-color);
} */

.image-size {
  height: 33px;
  cursor: pointer;
  width: 33px;
}

@media (max-width: 698px) {
  .landing-page-main {
    height: 100vh;
  }
  .footer {
    height: 7vh;
    font-size: 0.8rem;
  }
  .footer div span {
    display: block;
  }
  .image-size {
    height: 28px;
    width: 28px;
  }
}
